import { useContext } from 'react';

import FeatureFlagContext from 'context/FeatureFlagContext';

const useFeatureFlag = flag => {
  const { featureFlags } = useContext(FeatureFlagContext);

  return featureFlags[flag];
};

export default useFeatureFlag;
