import Analytics from 'analytics';

import { HOME_CHEF } from 'context/BrandContext';

import ahoyPixel from './ahoy';
import googleTagManagerDataLayer from './googleTagManagerDataLayer';

const plugIns = ({ canTrack }) => {
  if (canTrack) {
    return [googleTagManagerDataLayer(), ahoyPixel()];
  } else {
    return [];
  }
};

const analyticsInstance = args =>
  Analytics({
    app: HOME_CHEF,
    plugins: plugIns(args),
  });

export default analyticsInstance;
