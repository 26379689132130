import { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import CloseX from 'components/icons/navigation/CloseX';

export const MaintenanceBanner = ({ shown = false, closeFn }) => {
  const transitionRef = useRef();

  return (
    <CSSTransition
      classNames="scaleHeight"
      in={shown}
      timeout={200}
      nodeRef={transitionRef}
      unmountOnExit
    >
      <p
        className="py-sm text relative mb-0 w-full overflow-hidden bg-charcoal-100 py-2 pr-8 text-center font-normal text-white-100 transition-all"
        ref={transitionRef}
      >
        Home Chef will be unavailable from 1:30am to 3am CDT 6/7 for planned system maintenance. We
        apologize for any inconvenience.
        <button
          className="p-sm pt-xs vertical-center absolute right-0 flex appearance-none items-center border-none bg-transparent transition-all hover:text-charcoal-70 focus:text-charcoal-70"
          onClick={closeFn}
        >
          <CloseX className="h-6 w-6" />
          <span className="sr">Close this banner permanently</span>
        </button>
      </p>
    </CSSTransition>
  );
};

MaintenanceBanner.propTypes = {
  closeFn: PropTypes.func.isRequired,
  shown: PropTypes.bool,
};

export default MaintenanceBanner;
