/* global ahoy */

import { GENERIC_AHOY_EVENT, VIEW_REACTIVATION_EVENT } from 'lib/constants';

export default function ahoyPixel() {
  return {
    name: 'ahoy',
    track: ({ payload }) => {
      if (payload.event === VIEW_REACTIVATION_EVENT) {
        const { queryParams } = payload.properties;
        ahoy.track('reactivation_page_view', Object.fromEntries([...queryParams]));
      } else if (payload.event === GENERIC_AHOY_EVENT) {
        ahoy.track('Event', payload.properties);
      }
    },
  };
}
