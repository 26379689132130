import useFeatureFlag from 'hooks/useFeatureFlag';

const FeatureFlag = ({ flag, children }) => {
  const flagOn = useFeatureFlag(flag);

  if (flagOn) {
    return children;
  }
  return null;
};

FeatureFlag.propTypes = {
  flag: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default FeatureFlag;
