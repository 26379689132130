import { useState, useCallback } from 'react';

const useSwipeable = swipeableProps => {
  swipeableProps = swipeableProps || {};

  const successCallback = swipeableProps.successCallback || function () {};

  const step = swipeableProps.step;
  const totalSteps = swipeableProps.totalSteps;

  const LEFT = 'left';
  const RIGHT = 'right';
  const [startX, setStartX] = useState(0);
  const [componentWidth, setComponentWidth] = useState(0);

  const [threshold, setThreshold] = useState(false);
  const [isSwiping, setSwiping] = useState(false);
  const [direction, setDirection] = useState(RIGHT);
  const [stepClass, setStepClass] = useState('');

  const getEventInfo = event => {
    let eventList = event.changedTouches ? event.changedTouches[0] : event;
    return {
      clientX: eventList.clientX,
      clientY: eventList.clientY,
      currentTarget: event.currentTarget,
    };
  };

  const previousStep = () => {
    const currentStep = step - 1;
    setStepClass(`step${currentStep}`);
  };

  const nextStep = () => {
    const currentStep = step + 1;
    setStepClass(`step${currentStep}`);
  };

  const toStep = index => {
    setStepClass(`step${index}`);
  };

  /* Touch and Drag Events */
  const handleMoveStart = useCallback(
    event => {
      const { clientX, currentTarget } = getEventInfo(event);
      setComponentWidth(currentTarget.offsetWidth);
      setStartX(clientX);
      setSwiping(true);
    },
    [setSwiping, setStartX, setComponentWidth]
  );

  const handleMove = useCallback(
    event => {
      if (isSwiping) {
        let percentage = 0;
        const { clientX, currentTarget } = getEventInfo(event);
        const progressX = startX - clientX;
        const translation = parseInt(Math.abs(progressX));
        /* Swiping left */
        if (progressX > 0 && totalSteps - 1 != step) {
          percentage = parseInt((translation / componentWidth) * 100);
          if (percentage > 5) {
            setDirection(LEFT);
            const newPercentage = percentage + step * 100;
            currentTarget.style.transform = `translateX(-${newPercentage}%)`;
          }
          /* Swiping Right */
        } else if (progressX < 0 && step > 0) {
          percentage = parseInt((translation / componentWidth) * 100);
          /* Prevent accidental action on drag when scrolling down */
          if (percentage > 5) {
            setDirection(RIGHT);
            const newPercentage = percentage - step * 100;
            currentTarget.style.transform = `translateX(${newPercentage}%)`;
          }
        }
        /* Prevents a click from being read as swipe and only shows text once threshold has been achieved */
        if (!threshold && percentage > 15) {
          setThreshold(true);
        }
      }
    },
    [setThreshold, startX, componentWidth, threshold, isSwiping]
  );

  const handleMoveEnd = useCallback(
    event => {
      const { currentTarget } = getEventInfo(event);
      if (threshold) {
        let currentStep = direction === LEFT ? step + 1 : step - 1;
        successCallback(event, currentStep);
        setStepClass(`step${currentStep}`);
        currentTarget.style.removeProperty('transform');
      } else {
        setStepClass(`step${step}`);
      }
      /* reset hook  */
      setSwiping(false);
      setThreshold(false);
    },
    [setSwiping, successCallback, threshold, setThreshold]
  );

  return {
    handleMoveStart,
    handleMove,
    handleMoveEnd,
    isSwiping,
    nextStep,
    previousStep,
    toStep,
    stepClass,
  };
};

export default useSwipeable;
