import classNames from 'classnames';

const CreditCard = ({ className = '' }) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    className={classNames('icon', 'iconXl', className)}
  >
    <title>Credit Card</title>
    <g fillRule="evenodd">
      <polygon fill="currentColor" opacity="0.5" points="3 11 21 11 21 8 3 8" />
      <path
        fill="currentColor"
        d="M4,4 L20,4 C21.624789,4 23,5.2033093 23,6.75 L23,17.25 C23,18.7966908 21.624789,20 20,20 L4,20 C2.37521036,20 1,18.796691 1,17.25 L1,6.75 C1,5.20330911 2.37521039,4 4,4 Z M4,6 C3.42478975,6 3,6.37169104 3,6.75 L3,17.25 C3,17.628309 3.42478971,18 4,18 L20,18 C20.5752098,18 21,17.6283087 21,17.25 L21,6.75 C21,6.37169139 20.5752097,6 20,6 L4,6 Z"
      />
    </g>
  </svg>
);

CreditCard.propTypes = {
  className: PropTypes.string,
};

export default CreditCard;
