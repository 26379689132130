import classNames from 'classnames';

import { TEMPO } from 'context/BrandContext';
import useBrand from 'hooks/useBrand';
import style from 'styles/components/messageCenter/GlobalMessage';

const ACTION_LINK_TYPE = 'link';

const STYLE_ERROR_TYPE = 'error';
const STYLE_SUCCESS_TYPE = 'success';

const GlobalMessage = ({
  messageStyle = STYLE_ERROR_TYPE,
  url = '',
  action,
  actionFunction,
  actionText,
  heading,
  icon,
  isSubmitting,
  subheading,
}) => {
  const isTempo = useBrand(TEMPO);

  const buttonClasses = classNames('btn bp2:min-w-48', {
    btnLimeGreen: isTempo && messageStyle === STYLE_SUCCESS_TYPE,
    'btnOutlined btnPrimary': !isTempo && messageStyle === STYLE_SUCCESS_TYPE,
    'btnOutlined btnCaution': messageStyle === STYLE_ERROR_TYPE,
  });

  return (
    <div
      className={classNames(style[messageStyle], {
        [style['success--tempo']]: isTempo && messageStyle === STYLE_SUCCESS_TYPE,
      })}
    >
      <div className={classNames('flex', 'justify-between', 'p-4', style.banner)}>
        <div className={classNames('flex', 'items-center', style.textContainer)}>
          {icon}
          <div>
            <p className={classNames(style.heading, 'h4 mb-0')}>{heading}</p>
            <p className={classNames(style.description, 'text')}>{subheading}</p>
          </div>
        </div>
        {action === ACTION_LINK_TYPE ? (
          <a href={url} alt={actionText} className={buttonClasses}>
            {actionText}
          </a>
        ) : (
          <button onClick={actionFunction} className={buttonClasses} disabled={isSubmitting}>
            {actionText}
          </button>
        )}
      </div>
    </div>
  );
};

GlobalMessage.propTypes = {
  action: PropTypes.string.isRequired,
  actionFunction: PropTypes.func.isRequired,
  actionText: PropTypes.string.isRequired,
  subheading: PropTypes.string,
  heading: PropTypes.string.isRequired,
  icon: PropTypes.node,
  messageStyle: PropTypes.oneOf([STYLE_ERROR_TYPE, STYLE_SUCCESS_TYPE]),
  url: PropTypes.string,
  isSubmitting: PropTypes.bool.isRequired,
};

export default GlobalMessage;
