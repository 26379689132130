const Bell = ({ className }) => (
  <svg data-name="bell" viewBox="0 0 24 24" className={`icon h-8 w-8 ${className}`}>
    <path d="M12,21.47a1.84,1.84,0,0,0,1.78-1.89H10.22A1.84,1.84,0,0,0,12,21.47Z" />
    <path d="M19.47,16.58l-.77-.74a2,2,0,0,1-.58-1.41V10.27a6,6,0,0,0-4.71-5.76V3.89a1.41,1.41,0,0,0-2.82,0v.62a6,6,0,0,0-4.71,5.76v4.16a2,2,0,0,1-.58,1.41l-.77.74A1.79,1.79,0,0,0,4,17.86a.74.74,0,0,0,.72.77H19.28a.74.74,0,0,0,.72-.77A1.79,1.79,0,0,0,19.47,16.58Z" />
  </svg>
);

Bell.propTypes = {
  className: PropTypes.string,
};

export default Bell;
