import classNames from 'classnames';

const FilledCircle = ({ className = '' }) => (
  <svg
    className={classNames('icon', className)}
    id="FilledCircle"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 80 80"
  >
    <circle cx="40" cy="40" r="28" fill="currentColor" />
  </svg>
);

FilledCircle.propTypes = {
  className: PropTypes.string,
};

export default FilledCircle;
