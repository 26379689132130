import { useCallback } from 'react';

/**
 * For appending additional analytics arguments to a callback function.
 * The additional args are:
 * - `originalUrl`: The current full URL when the callback is run.
 * - `eventDeduplicationId`: The id found on the root element of the app.
 *
 * Example:
  const PauseMessage = ({ onUnpause }) => {
    const onReactivate = useAnalyticsCallback(onUnpause);
    return <button onClick={onReactivate}>Reactivate Your Account</button>;
  }
 * @param {function} callbackFn
 * @param {string} rootId - the id of the root element of the React app from which the callback occurs
 * @param {array|undefined} dependencies - React hook dependencies
 *  If no dependencies are provided, falls back to [location.pathname, rootId, callbackFn]
 * @return {function} - memoized based on dependencies like any useCallback React hook
 */

const useAnalyticsArgs = (callbackFn, rootId, dependencies = []) => {
  const originalUrl = window.location.href;
  const eventDeduplicationId = document
    .getElementById(rootId)
    ?.getAttribute('data-event-deduplication-id');

  return useCallback(
    (...callbackArgs) => {
      return callbackFn(...callbackArgs, {
        originalUrl,
        eventDeduplicationId,
      });
    },
    [originalUrl, callbackFn, rootId, ...dependencies]
  );
};

export default useAnalyticsArgs;
