import classNames from 'classnames';
import { useState, useRef } from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';

import BodyModal from 'components/common/modals/BodyModal';
import { COLOR_SCHEME_GRAY } from 'components/common/modals/Modal';
import ModalSlide from 'components/common/modals/onboarding/ModalSlide';
import StepIndicatorWithButtons from 'components/common/StepIndicatorWithButtons';
import CustomizeOrdersMobile from 'images/customize-your-orders-mobile.gif';
import CustomizeOrdersDesktop from 'images/customize-your-orders.gif';
import ManageDeliveriesDesktop from 'images/manage-deliveries.gif';
import ManageDeliveriesMobile from 'images/manage-your-deliveries-mobile.gif';
import ReferFriendsMobile from 'images/refer-your-friends-mobile.png';
import ReferFriendsDesktop from 'images/refer-your-friends.gif';
import MenusGifMobile from 'images/view-upcoming-orders-mobile.gif';
import MenusGifDesktop from 'images/view-upcoming-orders.gif';
import * as Amplitude from 'services/amplitude';
import style from 'styles/components/common/modals/OnboardingModal';

/* eslint-disable quotes */

const steps = [
  {
    title: 'View Upcoming Orders',
    description:
      'View scheduled deliveries on the Upcoming Orders page. Use the calendar to navigate through your orders. Skip orders or select "Edit Order" to update selections for that week.',
    dskImg: MenusGifDesktop,
    mblImg: MenusGifMobile,
  },
  {
    title: 'Customize Your Orders',
    description:
      'Use the Edit Order page to add or remove recipes from your order and update servings. Select "Customize It" to swap or upgrade proteins to better suit your needs. View your order summary after finalizing your selections.',
    dskImg: CustomizeOrdersDesktop,
    mblImg: CustomizeOrdersMobile,
  },
  {
    title: 'Manage Deliveries',
    description:
      'Use the "Manage Delivery" link on the Edit Order page to update your delivery date or address for that order.',
    dskImg: ManageDeliveriesDesktop,
    mblImg: ManageDeliveriesMobile,
  },
  {
    title: 'Refer Friends, Earn Credit',
    description:
      "Invite your friends to sign up with your referral link. You'll earn Home Chef credit when they receive their first order. Invitations limited to new households only.",
    dskImg: ReferFriendsDesktop,
    mblImg: ReferFriendsMobile,
  },
];

const OnboardingModal = ({ onClose, onDismiss, shown }) => {
  const modalRef = useRef();
  const slideRef = useRef();
  const [step, setStep] = useState(0);
  const currentStep = steps[step];

  const stepIndicatorOnClick = clickedStep => {
    setStep(clickedStep);
  };

  const onPreviousStep = () => {
    setStep(step - 1);
    Amplitude.track({ type: Amplitude.ONBOARDING_MODAL_PREVIOUS });
  };

  const onNextStep = () => {
    step === steps.length - 1 ? onDismiss() : setStep(step + 1);
    Amplitude.track({ type: Amplitude.ONBOARDING_MODAL_NEXT });
  };

  return (
    <CSSTransition
      key="onboardingModal"
      in={shown}
      timeout={100}
      classNames="fadeInOut"
      unmountOnExit
      onExited={() => setStep(0)}
      nodeRef={modalRef}
    >
      <BodyModal
        containerStyle={classNames(style.container, 'onboarding-modal')}
        onDismiss={onClose}
        title="Getting Started"
        showIcon={false}
        colorScheme={COLOR_SCHEME_GRAY}
        transitionRef={modalRef}
      >
        <SwitchTransition mode="out-in">
          <CSSTransition
            key={`dash-modal-${step}`}
            classNames="fadeInOut"
            timeout={500}
            nodeRef={slideRef}
          >
            <ModalSlide
              title={currentStep.title}
              description={currentStep.description}
              dskImg={currentStep.dskImg}
              mblImg={currentStep.mblImg}
              largeHeader={true}
              transitionRef={slideRef}
            />
          </CSSTransition>
        </SwitchTransition>
        <StepIndicatorWithButtons
          currentStep={step}
          numSteps={4}
          stepIndicatorOnClick={stepIndicatorOnClick}
          onPreviousStep={onPreviousStep}
          onNextStep={onNextStep}
          lastStepButtonText="Get Started"
          wrapperClassName="mt-6"
          prevButtonWrapperClassName="min-w-150"
          nextButtonWrapperClassName="min-w-150 flex justify-end"
        />
      </BodyModal>
    </CSSTransition>
  );
};

OnboardingModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  shown: PropTypes.bool.isRequired,
};

export default OnboardingModal;
