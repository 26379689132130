import cn from 'classnames';

const CaretRight = ({ className = '' }) => (
  <svg viewBox="0 0 24 24" className={cn('icon', className)} role="presentation" aria-hidden={true}>
    <title>Right Caret</title>
    <path
      fill="currentColor"
      d="M13.5857859,11.9999996 L8.29289268,17.2928929 C7.90236839,17.6834172 7.90236839,18.3165821 8.29289268,18.7071064 C8.68341697,19.0976307 9.31658195,19.0976307 9.70710624,18.7071064 L15.7071062,12.7071064 C16.0976305,12.3165821 16.0976305,11.6834172 15.7071062,11.2928929 L9.70710624,5.29289286 C9.31658195,4.90236857 8.68341697,4.90236857 8.29289268,5.29289286 C7.90236839,5.68341715 7.90236839,6.31658213 8.29289268,6.70710642 L13.5857859,11.9999996 Z"
    />
  </svg>
);

CaretRight.propTypes = {
  className: PropTypes.string,
};

export default CaretRight;
