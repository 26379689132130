import cn from 'classnames';
import get from 'lodash/get';
import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useAnalytics } from 'use-analytics';

import { dismiss } from 'actions/dismissals';
import {
  batchUpdateMessages,
  updateMessage,
  messageInteraction,
  expireMessage,
  bootstrapMessageCenter,
  refreshMessages,
} from 'actions/messageCenter';
import { getUser, unpause } from 'actions/user';
import BodyPortal from 'components/common/BodyPortal';
import FeatureFlag from 'components/common/FeatureFlag';
import IdPortal from 'components/common/IdPortal';
import SignUpOnboardingModal from 'components/common/modals/onboarding/signup/OnboardingModal';
import Slideout, { WIDTH_500 } from 'components/common/Slideout';
import CreditCard from 'components/icons/accent/CreditCard';
import Bell from 'components/icons/Bell';
import NewBadge from 'components/icons/NewBadge';
import Pause from 'components/icons/Pause';
import { MAINTENANCE_BANNER_KEY } from 'helpers/dismissals';
import * as Routes from 'helpers/routes';
import useAnalyticsArgs from 'hooks/useAnalyticsArgs';
import useAnalyticsCallback from 'hooks/useAnalyticsCallback';
import usePrevious from 'hooks/usePrevious';
import { SHOW_MAINTENANCE_BANNER, TEMPO, REACTIVATION_EVENT } from 'lib/constants';
import * as fromRoot from 'reducers';
import * as fromDismissals from 'reducers/dismissals';
import * as fromMessageCenter from 'reducers/messageCenter';
import { selectRestartCampaign } from 'reducers/restartCampaign';
import * as Amplitude from 'services/amplitude';
import * as ErrorLogging from 'services/ErrorLogging';
import style from 'styles/components/messageCenter/MessageCenterContainer';
import * as Types from 'utilities/types';

import DrawerMessagesContainer from './DrawerMessagesContainer';
import GlobalMessage from './GlobalMessage';
import MaintenanceBanner from './MaintenanceBanner';
import PauseMessage from './PauseMessage';
import PromotionCarousel from './PromotionCarousel';

const MessageCenterContainer = ({
  drawerMessages,
  globalMessage,
  hasViewedMaintenanceBanner,
  isMessageDismissed,
  needsInitializing,
  onBootstrapMessageCenter,
  onClickMessage,
  onDismiss,
  onGetUser,
  onExpireMessage,
  onRefreshMessages,
  onUnpause,
  onUpdateMessages,
  onUpdateMessage,
  pauseMessage,
  restartCampaign,
  unreadMessages,
  user,
  zoneZeroPromotions,
}) => {
  const root = document.querySelector('#hc-notifications-react-app');
  const eventDeduplicationId = root?.getAttribute('data-event-deduplication-id');
  const isPause = ['pause', 'pause-with-restart', 'california-cancel'].includes(
    globalMessage?.name
  );

  const analytics = useAnalytics();
  const [drawerShown, setDrawerShown] = useState(false);
  const [maintenanceShown, setMaintenanceShown] = useState(!hasViewedMaintenanceBanner);
  const [onboardingModalShown, setOnboardingModalShown] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const messageStyle = globalMessage && isPause ? 'success' : 'error';

  const onReactivate = useAnalyticsArgs(onUnpause, 'hc-notifications-react-app');

  useEffect(() => {
    if (isPause && !user.isLoaded) {
      onGetUser({ params: { detailLevel: 'summary' } });
    }
  }, [user.isLoaded, isPause]);

  const trackAnalytics = () => {
    try {
      analytics.track(REACTIVATION_EVENT, {
        eventDeduplicationId,
        userUuid: user?.uuid || restartCampaign.userUuid,
      });
    } catch (error) {
      ErrorLogging.captureException(error);
    }
  };

  const getAction = action => {
    return {
      [Types.UNPAUSE_ACTION]: onReactivate,
    }[action];
  };

  const markReadOnClick = message => {
    const updateParams = { id: message.id, read: true };
    onClickMessage(message);
    if (!message.readAt) {
      onUpdateMessage(updateParams);
    }
  };

  const globalMessageAction = useCallback(() => {
    const actionFunc = getAction(globalMessage.action);
    if (!actionFunc) return () => {};
    return async () => {
      setIsSubmitting(true);
      const response = await actionFunc();
      setIsSubmitting(false);
      if (response.ok) {
        if (isPause) trackAnalytics();
        if (response?.user?.accountVerificationRequired) {
          window.location.assign(Routes.ACCOUNT_VERIFY_PATH);
        } else {
          window.location.assign(Routes.dashboardRootPath());
        }
      }
    };
  }, [get(globalMessage, 'heading', ''), trackAnalytics, isPause]);

  const globalMessageIcon = useCallback(() => {
    const iconClasses = 'hidden bp2:block mr-2';
    return {
      [Types.ICON_PAUSE]: <Pause className={iconClasses} />,
      [Types.ICON_CREDIT_CARD]: <CreditCard className={iconClasses} />,
      [Types.ICON_NEW]: <NewBadge className={iconClasses} />,
    }[globalMessage.icon];
  }, [get(globalMessage, 'icon', '')]);

  const onSetDrawerShown = useAnalyticsCallback(
    () => setDrawerShown(true),
    ({ logEvent, events }) => {
      logEvent(events.messageCenter.drawerShown);
    },
    [setDrawerShown]
  );

  const onDismissMaintenanceBannerCallback = useCallback(() => {
    setMaintenanceShown(false);
    return onDismiss(MAINTENANCE_BANNER_KEY);
  });

  const onOpenOnboardingModal = () => {
    setDrawerShown(false);
    setOnboardingModalShown(true);
    Amplitude.track({ type: Amplitude.ONBOARDING_MODAL_SHOW });
  };

  const dismissOnboarding = () => {
    setOnboardingModalShown(false);
    Amplitude.track({ type: Amplitude.ONBOARDING_MODAL_DISMISS });
  };

  const closeOnboarding = () => {
    setOnboardingModalShown(false);
  };

  const totalUnreadMessages = unreadMessages.length;

  const renderBell = () => (
    <button
      onClick={onSetDrawerShown}
      className={cn(style.iconPadding, 'relative appearance-none border-none')}
      data-testid="messageCenterBell"
    >
      <Bell className="iconLg" />
      {totalUnreadMessages > 0 && <span className={style.circle}>{totalUnreadMessages}</span>}
    </button>
  );

  useEffect(() => {
    if (
      window.location.pathname === Routes.ACCOUNT_REACTIVATE_OFFER_PATH ||
      window.location.pathname === Routes.BOX_REACTIVATIONS_PATH
    )
      return;
    const onInitialize = async () => await onBootstrapMessageCenter();
    if (needsInitializing) onInitialize();
  }, [needsInitializing]);

  const previousUserMealPlan = usePrevious(user.mealPlanSlug);

  useEffect(() => {
    const userSwitchedFromTempo = previousUserMealPlan === TEMPO && user.mealPlanSlug !== TEMPO;
    const userSwitchedToTempo =
      previousUserMealPlan !== TEMPO && previousUserMealPlan && user.mealPlanSlug === TEMPO;

    if (userSwitchedFromTempo || userSwitchedToTempo) {
      onRefreshMessages();
    }
  }, [user.mealPlanSlug]);

  return (
    <>
      <FeatureFlag flag={SHOW_MAINTENANCE_BANNER}>
        <MaintenanceBanner shown={maintenanceShown} closeFn={onDismissMaintenanceBannerCallback} />
      </FeatureFlag>

      <div id="messageAlertContainer">
        {pauseMessage ? (
          <PauseMessage
            {...globalMessage}
            actionFunction={globalMessageAction()}
            user={user}
            isSubmitting={isSubmitting}
          />
        ) : (
          globalMessage && (
            <GlobalMessage
              {...globalMessage}
              icon={globalMessageIcon()}
              actionFunction={globalMessageAction()}
              messageStyle={messageStyle}
              userUuid={user.uuid}
              isSubmitting={isSubmitting}
            />
          )
        )}
      </div>
      <IdPortal id="message-center-bell-mobile-portal">{renderBell()}</IdPortal>
      <IdPortal id="message-center-bell-tbl-portal">{renderBell()}</IdPortal>
      <IdPortal id="message-center-bell-web-portal">{renderBell()}</IdPortal>
      <BodyPortal>
        <Slideout
          id="message-center-modal-dialog"
          labelId="message-center-label"
          show={drawerShown}
          closeCallback={() => setDrawerShown(false)}
          desktopWidth={WIDTH_500}
        >
          <h1 id="message-center-label" className="h2 mx-6 mt-4">
            Messages
          </h1>
          <DrawerMessagesContainer
            messages={drawerMessages}
            onUpdateMessages={onUpdateMessages}
            onExpireMessage={onExpireMessage}
            onClickMessage={markReadOnClick}
            onOpenOnboardingModal={onOpenOnboardingModal}
          />
        </Slideout>
      </BodyPortal>
      <IdPortal id="promotion-portal">
        <PromotionCarousel
          isMessageDismissed={isMessageDismissed}
          zoneZeroPromotions={zoneZeroPromotions}
          onDismiss={onDismiss}
        />
      </IdPortal>
      <SignUpOnboardingModal
        onClose={closeOnboarding}
        onDismiss={dismissOnboarding}
        shown={onboardingModalShown}
      />
    </>
  );
};

MessageCenterContainer.propTypes = {
  globalMessage: Types.message,
  pauseMessage: Types.message,
  drawerMessages: PropTypes.arrayOf(Types.message).isRequired,
  unreadMessages: PropTypes.arrayOf(Types.message).isRequired,
  isMessageDismissed: PropTypes.func.isRequired,
  promotionMessage: Types.message,
  onClickMessage: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  needsInitializing: PropTypes.bool.isRequired,
  onBootstrapMessageCenter: PropTypes.func.isRequired,
  onRefreshMessages: PropTypes.func.isRequired,
  onUpdateMessages: PropTypes.func.isRequired,
  onUpdateMessage: PropTypes.func.isRequired,
  onUnpause: PropTypes.func.isRequired,
  hasViewedMaintenanceBanner: PropTypes.bool.isRequired,
  zoneZeroPromotions: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  onExpireMessage: PropTypes.func.isRequired,
  userLoading: PropTypes.bool.isRequired,
  restartCampaign: PropTypes.object.isRequired,
  onGetUser: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    globalMessage: fromMessageCenter.selectMessageByPlacementAndPriority(state, 'global'),
    drawerMessages: fromMessageCenter.selectOrderedDrawerMessages(state),
    pauseMessage: fromMessageCenter.pauseMessage(state),
    unreadMessages: fromMessageCenter.selectUnreadDrawerMessages(state),
    isMessageDismissed: key => fromDismissals.selectIsDismissed(state, key),
    needsInitializing: fromMessageCenter.needsInitializing(state),
    user: fromRoot.selectUser(state),
    hasViewedMaintenanceBanner: fromDismissals.selectIsDismissed(state, MAINTENANCE_BANNER_KEY),
    zoneZeroPromotions: fromMessageCenter.selectZoneZeroPromotions(state),
    userLoading: fromRoot.selectUserLoadingStatus(state),
    restartCampaign: selectRestartCampaign(state),
  }),
  {
    onBootstrapMessageCenter: bootstrapMessageCenter,
    onRefreshMessages: refreshMessages,
    onDismiss: dismiss,
    onUnpause: args => unpause(Amplitude.UNPAUSE_FROM_BANNER, args),
    onUpdateMessages: batchUpdateMessages,
    onUpdateMessage: updateMessage,
    onClickMessage: messageInteraction,
    onExpireMessage: expireMessage,
    onGetUser: getUser,
  }
)(MessageCenterContainer);
