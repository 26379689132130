import classNames from 'classnames';

const CheckCircle = ({ className = '' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    className={classNames('icon', className)}
  >
    <path
      fill="currentColor"
      d="M0,50C0,22.39,22.39,0,50,0s50,22.39,50,50s-22.39,50-50,50S0,77.61,0,50z M75.56,38.06c0-0.82-0.33-1.65-0.93-2.24 l-4.48-4.49c-0.6-0.59-1.42-0.92-2.25-0.92c-0.82,0-1.65,0.33-2.24,0.92L44.03,53l-9.69-9.73c-0.59-0.59-1.42-0.92-2.24-0.92 s-1.65,0.33-2.24,0.92l-4.49,4.49c-0.59,0.59-0.92,1.42-0.92,2.24s0.33,1.65,0.92,2.24L37.3,64.18l4.49,4.49 c0.59,0.59,1.42,0.92,2.24,0.92c0.82,0,1.65-0.33,2.24-0.92l4.49-4.49l23.87-23.87C75.23,39.71,75.56,38.89,75.56,38.06z"
    />
  </svg>
);

CheckCircle.propTypes = {
  className: PropTypes.string,
};

export default CheckCircle;
