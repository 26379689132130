import classNames from 'classnames';

const Pause = ({ className = '' }) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 80 80"
    className={classNames('icon', 'iconXl', className)}
  >
    <title>Paused</title>
    <path d="M36.76,30.27V49.73a3.25,3.25,0,0,1-6.49,0V30.27a3.25,3.25,0,0,1,6.49,0ZM46.49,27a3.24,3.24,0,0,0-3.25,3.24V49.73a3.25,3.25,0,0,0,6.49,0V30.27A3.24,3.24,0,0,0,46.49,27ZM72.43,40A32.43,32.43,0,1,1,40,7.57,32.43,32.43,0,0,1,72.43,40Zm-3.24,0A29.19,29.19,0,1,0,40,69.19,29.23,29.23,0,0,0,69.19,40Z" />
  </svg>
);

Pause.propTypes = {
  className: PropTypes.string,
};

export default Pause;
