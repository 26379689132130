import cn from 'classnames';

import FilledCircle from 'components/icons/FilledCircle';
import CloseX from 'components/icons/navigation/CloseX';
import { ONBOARDING_TOUR } from 'lib/constants';
import style from 'styles/components/messageCenter/DrawerMessage';

const buttonStyles =
  'mr-4 ml-auto self-center appearance-none border-none text-charcoal-100 hover:text-charcoal-80 focus:text-charcoal-80 bg-transparent';

const DrawerMessage = ({
  action = '',
  actionText = '',
  url = '',
  heading,
  name,
  onClickMessage,
  onExpireMessage,
  onOpenOnboardingModal,
  read,
  subheading,
}) => {
  const content = (
    <>
      <FilledCircle
        className={cn('flex self-center', {
          'text-charcoal-60': read,
          'text-orange-100': !read,
        })}
      />
      <div>
        <h2 className="textMd mb-2.5">{heading}</h2>
        <p className="text leading-tight">{subheading}</p>

        {name === ONBOARDING_TOUR && (
          <div>
            <button
              className="btn btnGhost p-0 pt-2 text-green-100 hover:text-green-120 focus:text-green-120"
              onClick={onOpenOnboardingModal}
            >
              {actionText}
            </button>
          </div>
        )}
      </div>
    </>
  );
  return (
    <li
      onClick={onClickMessage}
      className="mb-0 list-none border-b border-solid border-charcoal-60 hover:bg-charcoal-50 hover:text-charcoal-100"
    >
      {action === 'link' && url ? (
        <div className="flex">
          <a href={url} className={style.drawerMessage}>
            {content}
          </a>
          <button className={buttonStyles} onClick={onExpireMessage} aria-label="Delete message">
            <CloseX className="h-6 w-6 min-w-6" />
          </button>
        </div>
      ) : (
        <div className="flex">
          <div className={style.drawerMessage}>{content}</div>
          <button className={buttonStyles} onClick={onExpireMessage} aria-label="Delete message">
            <CloseX className="h-6 w-6 min-w-6" />
          </button>
        </div>
      )}
    </li>
  );
};

DrawerMessage.propTypes = {
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
  read: PropTypes.bool.isRequired,
  action: PropTypes.string,
  actionText: PropTypes.string,
  name: PropTypes.string,
  url: PropTypes.string,
  onClickMessage: PropTypes.func.isRequired,
  onExpireMessage: PropTypes.func.isRequired,
  onOpenOnboardingModal: PropTypes.func.isRequired,
};

export default DrawerMessage;
